import React from 'react';
import { NavLink } from 'react-router-dom';
import BlogCards from './BlogCards';
import Header from '../component/Header';
import Footer from '../component/Footer';

function Blogs({ blogs }) {

  return (
    <>
    <Header />
      <main className="nk-pages">
        {/* Resource Section Start */}
        <section className="nk-section overflow-hidden pt-120 pt-lg-160">
          <div className="nk-mask blur-1 left center" />
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8 col-xxl-6">
                <div className="nk-section-head">
                  <span
                    className="nk-section-subtitle"
                    data-aos="fade-up"
                    data-aos-delay={0}
                  >
                    Our News
                  </span>
                  <h2
                    className="nk-section-title"
                    data-aos="fade-up"
                    data-aos-delay={50}
                  >
                    Resource Center
                  </h2>
                  <p
                    className="nk-section-text"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    Unlock the potential of our resource center, accessing valuable
                    information and insights for your business growth.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="nk-filter-wrap pb-5 pb-md-7">
                  <form action="#">
                    <div className="form-group" data-aos="fade-up" data-aos-delay="400">
                      <div className="form-control-wrap">
                        <div className="form-control-icon start">
                          <em className="icon ni ni-search"></em>
                        </div>
                        <input type="text" className="form-control" placeholder="Search for articles" />
                      </div>
                    </div>
                  </form>
                  <ul className="nk-tag justify-content-center pt-4">
                    <li data-aos="fade-up" data-aos-delay="450">
                      <a href="#" className="nk-tag-item">all</a>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="500">
                      <a href="#" className="nk-tag-item">Growth</a>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="550">
                      <a href="#" className="nk-tag-item">Marketing</a>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="600">
                      <a href="#" className="nk-tag-item">Tech</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Resource Section End */}
        {/* Latest Section Start */}
        <section className="nk-section nk-blog-section">
          <div className="nk-mask blur-1 right bottom" />
          <div className="container">
            <div className="nk-section-head">
              <h2 className="nk-section-title mb-0">All Posts</h2>
            </div>
            <div className="row gy-5">
            {blogs.length > 0 ? (
                blogs.map((blog, index) => (
                  <BlogCards
                    key={index}
                    imageUrl={blog.imageUrl}
                    badgeText={blog.badgeText}
                    cardTitle={blog.cardTitle}
                    blogText={blog.blogText}
                    linkTo={`/blogs-single/${blog.id}`}
                  />
                ))
              ) : (
                <p className="nk-block-text text-center lead pb-4">No posts yet. Please check back later!</p>
              )}
            </div>
            <div className="nk-pagination-wrap d-flex flex-wrap flex-sm-nowrap align-items-center gap g-3 justify-content-center justify-content-md-between pt-5 pt-lg-7">
              <div className="nk-pagination-col">
                <p>Showing: <span className="text-gray-1200">1-4 of 23 Products</span></p>
              </div>
              <div className="nk-pagination-col">
                <nav aria-label="Page navigation example">
                  <ul className="pagination pagination-s1">
                    <li className="page-item d-none">
                      <NavLink className="page-link" to="#">
                        <em className="icon ni ni-chevron-left"></em>
                        <span className="d-none d-sm-inline-block">Prev</span>
                      </NavLink>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">2</a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">3</a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">4</a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <span className="d-none d-sm-inline-block">Next</span>
                        <em className="icon ni ni-chevron-right"></em>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="nk-block-head">
              <div className="nk-section-head">
                <ul className="nk-btn-group pt-4 justify-content-center pt-3 pt-md-5 pb-2">
                  <li>
                    <NavLink to={"/"} className="btn btn-primary">
                      Go Back Home
                    </NavLink>
                  </li>
                </ul>
                <hr className="mt-md-6" />
              </div>
            </div>
          </div>
        </section>
        {/* Latest Section End */}
        {/* CTA Section Start */}
        <section className="nk-section">
          <div className="container">
            <div
              className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="row g-0 align-items-center overflow-hidden">
                <div className="col-lg-7 order-lg-0 order-2">
                  <div className="nk-block-head-content mb-0">
                    <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                      Start billing your customers and boost your business finances
                    </h2>
                    <ul className="nk-btn-group flex-wrap ps-1">
                      <li>
                        <NavLink to="https://app.billery.io/auth/register" className="btn btn-white">
                          Get Started
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="nk-cta-img-wrap text-end ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                    <img
                      data-aos="fade-left"
                      data-aos-delay={200}
                      src="images/thumb/c.png"
                      alt="tracking-thumb"
                      className="me-n1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CTA Section End */}
        {/* NewsLetter Section Start */}
        <section className="nk-section nk-newsletter-section pb-lg-0">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-gray-50">
              <div className="col-lg-6 col-xl-4">
                <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
                  <h4 className="text-capitalize">Subscribe to our newsletter</h4>
                  <p className="fs-16">Join the 5000+ People That Uses Softnio Products.</p>
                </div>
              </div>
              <div className="col-md-10 col-lg-6 col-xl-5">
                <form data-action="form/subscribe.php" className="form-submit-init" method="post">
                  <div className="form-group nk-newsletter-one row">
                    <div className="col-md-8">
                      <div className="form-control-wrap mb-4 mb-md-0">
                        <input type="email" name="email" className="form-control text-base" placeholder="Enter Your Email" required />
                      </div>
                    </div>
                    <div className="col-md-4 ps-md-0">
                      <input type="submit" className="btn btn-primary btn-block h-100" value="Subscribe" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* NewsLetter Section End */}
      </main>
      <Footer />
    </>
  );
}

export default Blogs;
