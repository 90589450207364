import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://billery-dev-0dd7e2a8d738.herokuapp.com/admin/forgot-password', { email });

      // Show success message
      toast.success(response.data.message || "Reset link sent to your email!", {
        position: "top-center",
        autoClose: 3000,
      });

      // Optionally, you can clear the input field after sending the request
      setEmail('');
    } catch (error) {
      // Handle error appropriately
      toast.error(error.response?.data.message || "Failed to send reset link. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <main className="nk-pages">
        <div className="nk-split-page flex-column flex-xl-row">
          <div className="nk-split-col nk-auth-col justify-content-center">
            <div className="nk-form-card card p-0 card-gutter-md nk-auth-form-card mx-lg-9 mx-xl-auto" data-aos="fade-up">
              <div className="card-body">
                <div className="nk-form-card-head text-center pb-5">
                  <h3 className="title mb-2">Password Forgotten?</h3>
                  <p className="text">
                    Shouldn't be here{" "}
                    <NavLink to="/admin-login" className="btn-link text-primary">Login</NavLink>.
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="name@billery.io"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <button type="submit" className="btn btn-block btn-primary">Send Reset Link</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* .nk-form-card */}
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}

export default ForgotPassword;
