import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'

function Pricing() {
  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Pricing Section Start */}
  <section className="nk-section nk-section-pricing overflow-hidden pt-120 pb-5 pb-lg-120">
    <div className="container">
      <div className="nk-pricing-wrap nk-pricing-wrap-s1">
        <div className="row justify-content-center text-center">
          <div className="col-xl-7">
            <div className="nk-section-head mb-xl-7">
              <span className="nk-section-subtitle">Pricing Plan</span>
              <div>
                <h2 className="nk-section-title">Best Plan for Everyone</h2>
                <p className="nk-section-text">
                  Select a plan that best suit your business with our top notch
                  billing system. No hidden charges
                </p>
              </div>
              <div className="nk-pricing-action-wrap pt-5">
                <div className="nk-pricing-toggle nk-pricing-toggle-s1">
                  <input
                    className="price-toggle-input"
                    type="radio"
                    name="duration"
                    defaultValue="monthly"
                    id="monthly"
                    defaultChecked=""
                  />
                  <label htmlFor="monthly">Monthly</label>
                  <input
                    className="price-toggle-input"
                    type="radio"
                    name="duration"
                    defaultValue="yearly"
                    id="yearly"
                  />
                  <label htmlFor="yearly">Yearly</label>
                  <span className="switch-highlight" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-pricing-group">
          <div
            className="nk-pricing nk-pricing-s1 p-5 pe-3"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="nk-pricing-head mb-3">
              <div className="nk-month-amount amount-wrap">
                <span className="amount h3 mb-0">$0</span>
                <span className="amount-text fs-16">/month</span>
              </div>
              <div className="nk-year-amount amount-wrap">
                <span className="amount h3 mb-0">$0</span>
                <span className="amount-text fs-16">/year</span>
              </div>
            </div>
            <div className="nk-pricing-body">
              <div className="mb-4">
                <h5 className="title">Free</h5>
                <p className="fs-14 m-0">A simple start for everyone</p>
              </div>
              <ul className="nk-list-link fw-medium">
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Access all yor incoming bills</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Create up to 10 invoices monthly
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Create up to 10 customers monthly
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create 5 estimate monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create 5 receipt monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create 5 purchase order monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Track 5 time/mileage monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Bill in only 1 currency</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create only 1 business</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Accept card payment</span>
                </li>
              </ul>
            </div>
          </div>
          {/* <div class="nk-pricing nk-pricing-s1 p-5 ps-3" data-aos="fade-up" data-aos-delay="100">
                          <div class="nk-pricing-head mb-3">
                              <div class="nk-month-amount amount-wrap">
                                  <span class="amount h3 mb-0">$9.99</span>
                                  <span class="amount-text fs-16">/month</span>
                              </div>
                              <div class="nk-year-amount amount-wrap">
                                  <span class="amount h3 mb-0">$95.99</span>
                                  <span class="amount-text fs-16">/year</span>
                              </div>
                          </div>
                          <div class="nk-pricing-body">
                              <div class="mb-4">
                                  <h5 class="title">Basic</h5>
                                  <p class="fs-14 m-0">For most businesses that want to otpimize web queries</p>
                              </div>
                              <ul class="nk-list-link fw-medium">
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">100 monthly invoices</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">30 monthly customers</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">50 monthly estimate</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">50 monthly receipt</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">50 monthly purchase order</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">50 monthly time tracking</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">Bill in any currency</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">Create up to 3 businesses</span>
                                  </li>
                                  <li class="active">
                                      <em class="icon ni ni-check me-1"></em>
                                      <span class="fs-14">Accept online payment</span>
                                  </li>
                              </ul>
                          </div>
                      </div> */}
          <div
            className="nk-pricing nk-pricing-s1 p-5 active"
            data-aos="fade-up"
            data-aos-delay={50}
          >
            <div className="nk-pricing-badge-wrap p-5">
              <span className="badge badge-md rounded-pill fs-12 bg-white text-uppercase fw-semibold">
                most Popular
              </span>
            </div>
            <div className="nk-pricing-head mb-3">
              <div className="nk-month-amount amount-wrap">
                <span className="amount h3 mb-0">$9.99</span>
                <span className="amount-text fs-16">/month</span>
              </div>
              <div className="nk-year-amount amount-wrap">
                <span className="amount h3 mb-0">$95.90</span>
                <span className="amount-text fs-16">/year</span>
              </div>
            </div>
            <div className="nk-pricing-body">
              <div className="mb-4">
                <h5 className="title">Basic</h5>
                <p className="fs-14 m-0">Get more from the basic offer</p>
              </div>
              <ul className="nk-list-link fw-medium">
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Access all your incoming bills</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Create up to 100 invoices monthly
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Create up to 100 customers monthly
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create 50 estimates monthly </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create 50 receipt monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Create 50 purchase orders monthly
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Track 50 time/mileage monthly</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Bill in any currency and see conversions
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create up to 3 businesses</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Accept card payment</span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="nk-pricing nk-pricing-s1 p-5"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="nk-pricing-head mb-3">
              <div className="nk-month-amount amount-wrap">
                <span className="amount h2 mb-0">$19.99</span>
                <span className="amount-text fs-16">/month</span>
              </div>
              <div className="nk-year-amount amount-wrap">
                <span className="amount h2 mb-0">$191.90</span>
                <span className="amount-text fs-16">/year</span>
              </div>
            </div>
            <div className="nk-pricing-body">
              <div className="mb-4">
                <h5 className="title">Pro</h5>
                <p className="fs-14 m-0">Stay in sync with the pro plan</p>
              </div>
              <ul className="nk-list-link fw-medium">
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Access all your incoming bills</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unlimited invoices</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unlimited customers</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unlimited estimates</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unlimited sales receipts</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unllimited purchase orders</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Unllimited time tracking</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">
                    Bill in any currency and see conversions
                  </span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Create unlimited businesses</span>
                </li>
                <li className="active">
                  <em className="icon ni ni-check me-1" />
                  <span className="fs-14">Accept card payment</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section Start */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap bg-primary-gradient rounded-3 is-theme p-5 p-lg-7"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-gs align-items-center">
          <div className="col-lg-8">
            <div className="media-group flex-column flex-lg-row align-items-center ">
              <div className="media media-lg media-circle media-middle text-bg-white text-primary mb-2 mb-lg-0 me-lg-1">
                <em className="icon ni ni-bar-chart-fill" />
              </div>
              <div className="media-text text-center text-lg-start">
                <h3 className="m-0">Need a custom plan?</h3>
                <p className="fs-16">
                  We provide top notch custom solution for businesses to get
                  started.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <a href="contact-us.html" className="btn btn-white">
              Contact support
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End */}
  {/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-0 align-items-center overflow-hidden">
          <div className="col-lg-7 order-lg-0 order-2">
            <div className="nk-block-head-content  mb-0">
              <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                Start billing your customers and boost your business finances
              </h2>
              <ul className="nk-btn-group flex-wrap ps-1">
                <li>
                  <a
                    href="https://app.billery.io/auth/register"
                    className="btn btn-white"
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 order-lg-0 order-1">
            <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
              <img
                data-aos="fade-left"
                data-aos-delay={200}
                src="/images/thumb/c.png"
                alt="tracking-thumb"
                className="me-n1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default Pricing