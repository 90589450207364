import React from 'react'
import Aos from 'aos';
import { useEffect } from 'react';
import Header from '../component/Header'
import Footer from '../component/Footer';

function About() {
  useEffect(() => {
    Aos.init();
},[])
  return (
    <>
    <Header />
    <main className="nk-pages">
  {/* Story Section Start */}
  <section className="nk-section pt-120 pt-lg-160">
    <div className="nk-mask blur-1 left center" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-7">
          <div className="nk-section-head text-center">
            <span
              className="nk-section-subtitle"
              data-aos="fade-up"
              data-aos-delay={0}
            >
              What is Billery?
            </span>
            <h2
              className="nk-section-title"
              data-aos="fade-up"
              data-aos-delay={50}
            >
              Helping you bill better
            </h2>
            <p
              className="nk-section-text"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              We are an end-to-end billing platform that helps small businesses
              bill their customers and get paid faster.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Story Section Start */}
  {/* Features Section Start */}
  {/* <section class="nk-section">
          <div class="container">
              <div class="row gy-5">
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                      <div class="card">
                          <div class="card-body">
                              <div class="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                                  <div class="media-text">
                                      <div class="h1 text-danger">500+</div>
                                  </div>
                                  <div class="media-text m-0">
                                      <h4 class="text-capitalize">Active Users</h4>
                                      <p> We have a diverse range of active users using Billery. </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="50">
                      <div class="card">
                          <div class="card-body">
                              <div class="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                                  <div class="media-text">
                                      <div class="h1 text-warning">20+</div>
                                  </div>
                                  <div class="media-text m-0">
                                      <h4 class="text-capitalize">Active Countries</h4>
                                      <p>To send and receive bills and get paid. </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="0">
                      <div class="card">
                          <div class="card-body">
                              <div class="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                                  <div class="media-text">
                                      <div class="h1 text-primary">100%</div>
                                  </div>
                                  <div class="media-text m-0">
                                      <h4 class="text-capitalize">Active business</h4>
                                      <p> Reduction in operational costs </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="150">
                      <div class="card">
                          <div class="card-body">
                              <div class="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                                  <div class="media-text">
                                      <div class="h1 text-success">100%</div>
                                  </div>
                                  <div class="media-text m-0">
                                      <h4 class="text-capitalize">Customer satisfaction</h4>
                                      <p> Customer Satisfaction is our top priority. </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section> */}
  {/* Features Section End */}
  {/* Journey Section Start */}
  {/* our-mission Section Start  */}
  <section className="nk-section pb-7 pb-lg-120">
    <div className="container">
      <div className="row flex-row-reverse justify-content-between align-items-center">
        <div className="col-lg-5">
          <div
            className="nk-frame mb-5 mb-lg-0"
            data-aos="fade-up"
            data-aos-delay={50}
          >
            <img
              src="images/thumb/mission.png"
              alt="mission-cover"
              className="rounded-16 w-100 "
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="nk-section-haed pb-0">
            <span className="nk-section-subtitle">our mission</span>
            <h2 className="nk-section-title">
              <span className=" d-inline-block d-lg-block">
                {" "}
                To help <span className="text-primary">revolutionalize</span>
              </span>{" "}
              business billing process.
            </h2>
            <p className="nk-section-text">
              With the help of innovative technologies, we will help your
              business through a successful digital billing process.
            </p>
            <div className="row py-5"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* our-mission Section End   */}
  {/* our-journey Section Start  */}
  <section className="nk-section nk-section-journey  bg-blue-600 overflow-hidden">
    <div className="nk-mask z-1 shape-37 d-none d-xl-block" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-xxl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle">timeline</span>
            <h2 className="nk-section-title">Our Journey Defines Us</h2>
            <p className="nk-section-text">
              Our journey is a testament to our growth, resilience, and
              commitment to excellence, defining who we are today.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-section-content">
        <div className="journey-contents">
          <div
            className="journey-item h-100"
            data-aos="fade-up"
            data-aos-delay={0}
          >
            <div className="circle">
              <span className="circle-dot" />
            </div>
            <div className="card card-journey journey-card">
              <div className="card-journey-content">
                <div className="heading">
                  <span>January 2024</span>
                  <span>Company started</span>
                </div>
                <blockquote>
                  <p>
                    {" "}
                    Our company started with a vision to revolutionize the
                    billing industry by helping freelancers and small business
                    owners bill easily and get paid faster.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
          {/* <div class="journey-item h-100" data-aos="fade-up" data-aos-delay="0">
                          <div class="circle">
                              <span class="circle-dot"></span>
                          </div>
                          <div class="card card-journey journey-card">
                              <div class="card-journey-content">
                                  <div class="heading">
                                      <span>April 2024</span>
                                      <span>We joined The Prince's Trust UK</span>
                                  </div>
                                  <blockquote>
                                      <p> Exciting news! Our startup has officially joined The Prince's Trust, bringing our innovative billing services to the market.</p>
                                  </blockquote>
                              </div>
                          </div>
                      </div> */}
        </div>
      </div>
    </div>
  </section>
  {/*  our-journey Section End   */}
  {/* Values Section Start */}
  {/* Team Section Start */}
  {/* <section class="nk-section">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-8 col-xxl-6">
                      <div class="nk-section-head text-center">
                          <span class="nk-section-subtitle">team members</span>
                          <h2 class="nk-section-title">The Team Behind MyInvoicer</h2>
                          <p class="nk-section-text">Meet the dedicated team behind MyInvoicer, working tirelessly to bring you the best experience.</p>
                      </div>
                  </div>
              </div>
              <div class="row gy-5 px-7">
                  <div class="col-md-6 col-lg-5" data-aos="fade-up" data-aos-delay="0">
                      <div class="card border-0 bg-blue-300">
                          <div class="card-body pb-0">
                              <div class="card-title-group align-items-start mb-4">
                                  <div class="card-title-group-item">
                                      <h4 class="text-capitalize mb-1">Dominic Ovo Eburuoh</h4>
                                      <p class="fs-16">CEO &amp; Founder</p>
                                  </div>
                                  <div class="card-title-group-item">
                                      <a href="#" class="text-dark">
                                          <em class="icon icon-lg ni ni-linkedin-round"></em>
                                      </a>
                                  </div>
                              </div>
                              <div class="card-image text-center">
                                  <img src="images/team/CEO.png" alt="team" data-aos="fade-up" data-aos-delay="50">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 p-lg-7 col-lg-6">
                      <div class="nk-section-head pb-0">
                          <h2 class="nk-section-title">Meet the CEO</h2>
                          <p class="nk-section-text"> In 2023, Dominic established MyInvoicer through unwavering determination and by depleting his personal savings entirely. With his entire future at stake, he transformed his vision of accessible invoicing for all into a thriving platform that now handles invoicing for over 200,000 individuals and businesses.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section> */}
  {/* Team Section Start */}
  {/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-0 align-items-center overflow-hidden">
          <div className="col-lg-7 order-lg-0 order-2">
            <div className="nk-block-head-content  mb-0">
              <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                Start billing your customers and boost your business finances
              </h2>
              <ul className="nk-btn-group flex-wrap ps-1">
                <li>
                  <a
                    href="https://app.billery.io/auth/register"
                    className="btn btn-white"
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 order-lg-0 order-1">
            <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
              <img
                data-aos="fade-left"
                data-aos-delay={200}
                src="images/thumb/c.png"
                alt="tracking-thumb"
                className="me-n1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End  */}
</main>
<Footer />
    </>
  )
}

export default About