import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Preloader from './component/Preloader';
import Home from './pages/Home';
import About from './pages/About';
import BlogForm from './pages/BlogForm';
import BlogSingle from './pages/Blogs-single';
import Blogs from './pages/Blogs';
import Billing from './pages/Billing';
import Contact from './pages/Contact';
import Features from './pages/Features';
import HelpCenter from './pages/HelpCenter';
import Page404 from './pages/Page404';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';
import TermsAndCond from './pages/TermsAndCond';
import TopButton from './component/TopButton';
import Login from './pages/Login';
import './App.css'
import Aos from 'aos';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  const [blogs, setBlogs] = useState([]);

  const addBlog = (newBlog) => {
    const currentDate = new Date().toLocaleString(); // Get current date and time as a string
    setBlogs([...blogs, { ...newBlog, id: new Date().getTime().toString(), date: currentDate }]);
  };

  useEffect(() => {
    Aos.init();
},[])

  return (
    <>
      <Router>
        <Preloader />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/blog-form' element={<BlogForm onAddBlog={addBlog}  blogs={blogs} setBlogs={setBlogs}/>} />
          <Route path='/blogs-single/:id' element={<BlogSingle blogs={blogs} />} />
          <Route path='/blogs' element={<Blogs blogs={blogs} />} />
          <Route path='/billing' element={<Billing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/features' element={<Features />} />
          <Route path='/help-center' element={<HelpCenter />} />
          <Route path='/page-404' element={<Page404 />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms-and-condition' element={<TermsAndCond />} />
          <Route path='/admin-login' element={<Login />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
        <TopButton />
      </Router>
    </>
  );
}

export default App;
