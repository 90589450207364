import React from 'react'
import { NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react';


function Footer() {
  const [currentYear, setCurrentYear] = useState('');

  useEffect(()=> {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, [])
    const scrollUp = () =>{
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      })
  }
  return (
    <>
<footer className="nk-footer">
  <div className="nk-footer-top">
    <div className="container">
      <div className="nk-footer-content row justify-content-xl-between">
        <div className="col-md-8 col-lg-4 col-xxl-4">
          <div className="nk-footer-brand pb-5 pb-lg-0">
            <div className="nk-footer-brand-info mb-4">
              <div className="nk-footer-logo">
                <NavLink to="/" className="logo-link">
                  <div className="logo-wrap">
                    <img
                      className="logo-img"
                      src="images/billery-logo.svg"
                      alt="brand-logo"
                    />
                  </div>
                </NavLink>
              </div>
              <p>
                Streamline your business operations with our powerful suite of
                solutions. Boost productivity and drive growth with Billery.
              </p>
            </div>
            <ul className="nk-footer-social">
              <li>
                <NavLink to="https://twitter.com/billeryio" target="_blank">
                  <em className="icon ni ni-twitter" />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://instagram.com/billery.io" target="_blank">
                  <em className="icon ni ni-instagram" />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://facebook.com/billery.io" target="_blank">
                  <em className="icon ni ni-facebook-f" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-8 col-xxl-6">
          <div className="row justify-content-between">
            <div className="col-sm-8 col-md-7">
              <div className="nk-footer-info">
                <h5 className="title">Pages</h5>
                <ul className="row gy-1 gy-sm-4">
                  <li className="col-6">
                    <NavLink to="/" onClick={scrollUp}>Home</NavLink>
                  </li>
                  <li className="col-6">
                    <NavLink to={"/about"} onClick={scrollUp}>About</NavLink>
                  </li>
                  <li className="col-6">
                    <NavLink to={"/blogs"} onClick={scrollUp}>News</NavLink>
                  </li>
                  <li className="col-6">
                    <NavLink to={"/help-center"} onClick={scrollUp}>Help Center</NavLink>
                  </li>
                  <li className="col-6">
                    <NavLink to={"/contact"} onClick={scrollUp}>Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="nk-footer-info">
                <h5 className="title">Other Pages</h5>
                <ul className="row gy-1 gy-sm-4">
                  <li className="col-12">
                    <NavLink to="/help-center" onClick={scrollUp}>Support</NavLink>
                  </li>
                  <li className="col-12">
                    <NavLink to="/terms-and-condition" onClick={scrollUp}>Terms of service</NavLink>
                  </li>
                  <li className="col-12">
                    <NavLink to="/privacy" onClick={scrollUp}>Privacy policy</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="nk-footer-bottom">
    <div className="container">
      <div className="nk-footer-content row justify-content-between">
        <div className="col-lg-6 px-0">
          <p className="nk-footer-copyright-text text-center text-lg-start">
            ©<span id="currentYear" />{currentYear}
            <a className="fs-16" href="/" target="_blank">
              {" "}
              Billery
            </a>
            . All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>


    </>
  )
}

export default Footer