import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function ResetPassword() {
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  // Show success toast
  const showSuccessToast = () => {
    toast.success("Password reset successful!", {
      position: "top-center",
      autoClose: 3000,
    });
  };

  // Extract query parameters from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email'));
    setToken(params.get('token'));
  }, [location.search]);

  // Password visibility logic
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match!");
    } else {
      setPasswordError("");
      
      try {
        const response = await axios.post('https://billery-dev-0dd7e2a8d738.herokuapp.com/admin/reset-password', {
          email,
          token,
          newPassword: password,
        });

        // Show success message
        showSuccessToast();

        // Clear the form fields after successful reset
        setPassword("");
        setConfirmPassword("");
      } catch (error) {
        toast.error(error.response?.data.message || "Failed to reset password. Please try again.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <main className="nk-pages">
        <div className="nk-split-page flex-column flex-xl-row">
          <div className="nk-split-col nk-auth-col">
            <div
              className="nk-form-card card rounded-3 card-gutter-md nk-auth-form-card mx-md-9 mx-xl-auto"
              data-aos="fade-up"
            >
              <div className="card-body p-5">
                <div className="nk-form-card-head text-center pb-5">
                  <h3 className="title mb-2">Reset Password</h3>
                  <p className="text">
                    Shouldn't be here?{" "}
                    <NavLink to="/admin-login" className="btn-link text-primary">
                      Login
                    </NavLink>
                    .
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="form-group">
                        {/* New Password Field */}
                        <label className="form-label">New Password</label>
                        <div className="form-control-wrap">
                          <NavLink
                            href="/reset-password"
                            className="form-control-icon end password-toggle"
                            title="Toggle show/hide password"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <em className="fas fa-eye text-primary" />
                            ) : (
                              <em className="fas fa-eye-slash text-primary" />
                            )}
                          </NavLink>
                          <input
                            id="new-password"
                            type={showNewPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="At least 8 characters"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* Confirm Password Field */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Confirm Password</label>
                        <div className="form-control-wrap">
                          <NavLink
                            href="/reset-password"
                            className="form-control-icon end password-toggle"
                            title="Toggle show/hide password"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <em className="fas fa-eye text-primary" />
                            ) : (
                              <em className="fas fa-eye-slash text-primary" />
                            )}
                          </NavLink>
                          <input
                            id="confirm-password"
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password must match"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* Password Mismatch Error */}
                    {passwordError && (
                      <div className="col-12">
                        <p className="text-danger">{passwordError}</p>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="form-group">
                        <button className="btn btn-block btn-primary" type="submit">
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* .nk-form-card */}
          </div>
        </div>

        {/* Toast Container to render toast messages */}
        <ToastContainer />
      </main>
    </>
  );
}

export default ResetPassword;
