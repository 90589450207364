import React, { useState } from 'react';
import '../component/css/BlogForm.css';
import { NavLink } from 'react-router-dom';
import BlogCards from './BlogCards';

function BlogForm({ onAddBlog, blogs, setBlogs }) {  // Receive setBlogs as a prop

  const handleDelete = (id) => {
    const updatedBlogs = blogs.filter((blog) => blog.id !== id);
    setBlogs(updatedBlogs);
  };

  const [formData, setFormData] = useState({
    imageUrl: '',
    badgeText: '',
    cardTitle: '',
    blogText: '',
  });

  const [fileName, setFileName] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          imageUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddBlog(formData); // Use the onAddBlog function passed from App
    setFormData({
      imageUrl: '',
      badgeText: '',
      cardTitle: '',
      blogText: '',
    });
    setFileName('');
  };

  return (
    <>
      <div className="nk-app-root home-analytics">
        <header className="nk-header">
          <div className="nk-header-main nk-navbar-main  bg-white has-separate">
            <div className="container">
              <div className="nk-header-wrap">
                <div className="nk-header-logo">
                  <a href="index.html" className="logo-link">
                    <div className="logo-wrap">
                      <img
                        className="logo-img"
                        src="../images/billery-logo.svg"
                        alt="brand-logo"
                      />
                    </div>
                  </a>
                </div>
                <nav className="nk-header-menu nk-navbar">
                  <div>
                    <div className="nk-navbar-btn d-lg-none">
                      <ul className="nk-btn-group sm justify-content-center">
                        <li className="w-100">
                          <NavLink to={"/page-404" }className="btn btn-primary w-100">
                            <span>Logout</span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="nk-header-action">
                  <ul className="nk-btn-group sm justify-content-center align-items-center">
                    <li className="d-none d-md-block">
                      <NavLink to={"/page-404"} className="btn btn-primary w-100">
                        <span>Logout</span>
                      </NavLink>
                    </li>
                    <li className="nk-navbar-toggle">
                      <button className="btn btn-outline-dark navbar-toggle rounded-1 p-2 h-100">
                        <em className="icon ni ni-menu" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

      </div>
      <main className="nk-pages">
        <section className="nk-section pt-120 pt-lg-160">
          <div className="nk-mask blur-1 right center" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xxl-6">
                <div className="nk-section-head text-center">
                  <span className="nk-section-subtitle">Blog Post</span>
                  <h2 className="nk-section-title">Post a blog</h2>
                </div>
              </div>
            </div>
            <div className="row g-gs">
              <div className="col-lg-12" data-aos="fade-up" data-aos-delay={0}>
                <div className="card rounded-2">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} className="form-submit-init">
                      <div className="row g-gs">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">Badge Text</label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="badgeText"
                                className="form-control"
                                placeholder="Enter the badge text"
                                value={formData.badgeText}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Title</label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="cardTitle"
                                className="form-control"
                                placeholder="Enter the title"
                                value={formData.cardTitle}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Insert Banner</label>
                            <div className="form-control-wrap">
                              <label htmlFor="upload-image" className="btn btn-primary">
                                Upload Banner Image
                                <input
                                  type="file"
                                  id="upload-image"
                                  name="imageUrl"
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  onChange={handleFileChange}
                                  required
                                />
                              </label>
                              <span className="text-bg-primary-soft px-3">{fileName}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">Write a post</label>
                              <span>
                                <span id="char-count">0</span>/{" "}
                                <span id="char-max" data-char-max={255}>
                                  255
                                </span>
                              </span>
                            </div>
                            <div className="form-control-wrap">
                              <textarea
                                id="textarea-box"
                                name="blogText" 
                                className="form-control"
                                placeholder="Enter your post"
                                value={formData.blogText}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <button className="btn btn-primary" type="submit">
                              Post Blog
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="nk-section">
          <div className="nk-mask blur-1 right center" />
          <div className="container">
            <div className="nk-section-head pb-lg-5">
              <h2 className="nk-section-title">All posts</h2>
            </div>
            <div className="row gy-5">
              {blogs.length > 0 ? (
                blogs.map((blog, index) => (
                  <BlogCards
                    key={index}
                    imageUrl={blog.imageUrl}
                    badgeText={blog.badgeText}
                    cardTitle={blog.cardTitle}
                    blogText={blog.blogText}
                    linkTo={`/blogs-single/${blog.id}`}
                    onDelete={() => handleDelete(blog.id)}
                    onEdit={''}
                  />
                ))
              ) : (
                <p className="nk-block-text text-center lead pb-4">No posts yet. Please check back later!</p>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BlogForm;
