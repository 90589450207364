import React, { useState, useEffect } from "react";
import Aos from "aos";
import { NavLink } from "react-router-dom";
import Header from "../component/Header";
import Footer from '../component/Footer'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const formData = {
      name,
      email,
      message,
    };

    try {
      await axios.post(
        "https://billery-dev-0dd7e2a8d738.herokuapp.com/general/contact-us",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      // Flash success message
      toast.success("Message sent successfully!");

      // Clear the form after successful submission
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      if (error.response) {
        // Flash error message
        toast.error("Failed to send message");
      } else {
        toast.error("Network Error: " + error.message);
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Header />
      <main className="nk-pages">
        <ToastContainer />
        {/* Support Section Start */}
        <section className="nk-section pt-120 pt-lg-160">
          <div className="nk-mask blur-1 right center" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xxl-6">
                <div className="nk-section-head text-center">
                  <span className="nk-section-subtitle">Contact support</span>
                  <h2 className="nk-section-title">Get in touch</h2>
                  <p className="nk-section-text">
                    Get in touch for personalized assistance. We're here to help
                    and provide solutions tailored to your requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-gs">
              <div className="col-lg-8" data-aos="fade-up" data-aos-delay={0}>
                <div className="card rounded-2">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      className="form-submit-init"
                    >
                      <div className="row g-gs">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="user-name">
                              Full Name
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                id="user-name"
                                name="user-name"
                                className="form-control"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="user-email">
                              Email
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="email"
                                id="user-email"
                                name="user-email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="user-message"
                            >
                              Tell us about your query
                            </label>
                            <div className="form-control-wrap">
                              <textarea
                                id="user-message"
                                name="user-message"
                                className="form-control"
                                placeholder="Enter your message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={isSending}
                            >
                              {isSending ? "Sending..." : "Send Message"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-list">
                  <div
                    className="card rounded-2"
                    data-aos="fade-up"
                    data-aos-delay={50}
                  >
                    <div className="card-body">
                      <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-5">
                        <em className="icon ni ni-question" />
                      </div>
                      <h5>Frequently Asked Questions</h5>
                      <p className="line-clamp-2">
                        Find answers to commonly asked questions about our
                        product or service in our comprehensive FAQ section.
                      </p>
                      <div className="pt-2">
                        <NavLink
                          to="/help-center"
                          className="btn-link text-primary"
                        >
                          <span>See All Questions</span>
                          <em className="icon ni ni-arrow-right" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .card-list */}
              </div>
            </div>
          </div>
        </section>
        {/* Support Section End */}
        {/* CTA Section Start  */}
        <section className="nk-section">
          <div className="container">
            <div
              className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="row g-0 align-items-center overflow-hidden">
                <div className="col-lg-7 order-lg-0 order-2">
                  <div className="nk-block-head-content mb-0">
                    <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                      Start billing your customers and boost your business
                      finances
                    </h2>
                    <ul className="nk-btn-group flex-wrap ps-1">
                      <li>
                        <a
                          href="https://app.billery.io/auth/register"
                          className="btn btn-white"
                        >
                          Get Started
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="nk-cta-img-wrap text-end ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                    <img
                      data-aos="fade-left"
                      data-aos-delay={200}
                      src="images/thumb/c.png"
                      alt="tracking-thumb"
                      className="me-n1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CTA Section End  */}
      </main>
      <Footer />
    </>
  );
}

export default Contact;
