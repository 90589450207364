import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'

function Features() {
  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Discover Section Start */}
  <section className="nk-section pt-120 pt-lg-160 pb-7 pb-lg-120 overflow-hidden">
    <div className="nk-mask blur-1 left top " />
    <div className="nk-mask blur-1 right bottom " />
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 col-xxl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle" data-aos="fade-up">
              MyInvoicer Features
            </span>
            <h1
              className="nk-section-title"
              data-aos="fade-up"
              data-aos-delay={50}
            >
              More than an invoice{" "}
              <span className="d-lg-block">management system</span>
            </h1>
            <p
              className="nk-section-text"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              {" "}
              MyInvoicer offers you a seamless automated invoicing by providing
              a comprehensive and versatile tools to streamline your business
              finances.{" "}
            </p>
            <ul className="nk-btn-group justify-content-center pt-5">
              <li data-aos="fade-up" data-aos-delay={150}>
                <NavLink to="/" className="btn btn-primary">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div
          className="col-9 col-lg-10 col-xxl-9"
          data-aos="fade-up"
          data-aos-delay={400}
        >
          <div className="pt-6 mt-md-2 pb-7 pb-md-0">
            <div className="nk-banner-img nk-banner-img-s1">
              <img
                className="thumb-base border rounded-3"
                src="/images/thumb/myinvoicer home.png"
                alt="thumb-content"
              />
              <img
                className="thumb thumb-1 animate animate-shakeY animate-duration-12"
                src="/images/thumb/payments.png"
                alt="thumb-content"
              />
              <img
                className="thumb thumb-2 animate animate-shakeY animate-duration-12 animate-delay-1"
                src="/images/thumb/My-Invoicer-Online-Payment.png"
                alt="thumb-content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Discover Section End */}
  {/* Story-Line Section Start */}
  {/* <section class="nk-section pb-lg-7 pt-7 pt-lg-120">
          <div class="nk-mask blur-1 left center"></div>
          <div class="nk-mask blur-1 right bottom"></div>
          <div class="container">
              <div class="nk-section-content d-flex flex-column gap-7 gap-lg-120">
                  <div class="row gy-5 gy-lg-0 flex-row-reverse align-items-center justify-content-lg-between">
                      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="50">
                          <div class="nk-feature-overview-img mb-5 mb-lg-0">
                              <img src="/images/cover/cover-26.png" alt="cover-base" class="base">
                          </div>
                      </div>
                      <div class="col-lg-6 col-xl-5">
                          <div class="nk-section-head pb-0">
                              <h2 class="nk-section-title mb-0"> AI-generated invoices</span>
                              </h2>
                              <ul class="nk-list-dot d-flex flex-column gap-3 gap-lg-4 pt-4">
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="lead">With the help of our AI assistant, businesses can automatically populate invoice templates with relevant data, eliminating the need for manual data entry. This not only saves time but also reduces the risk of errors, ensuring the accuracy of invoiced amounts and details.</p>
                                  </li>
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="lead">Our AI assistant can handle complex calculations, including taxes, discounts, and currency conversions, with precision. This simplifies the invoicing process, especially for businesses operating internationally. Say goodbye to manual invoicing!</p>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="row gy-5 gy-lg-0 align-items-lg-center justify-content-lg-between">
                      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="50">
                          <div class="nk-feature-overview-img mb-5 mb-lg-0">
                              <img src="/images/cover/cover-25.png" alt="cover-base" class="base">
                          </div>
                      </div>
                      <div class="col-lg-6 col-xl-5">
                          <div class="nk-section-head pb-0">
                              <h2 class="nk-section-title mb-0"> Receive bills</h2>
                              <ul class="nk-list-dot d-flex flex-column gap-3 gap-lg-4 pt-4">
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="fs-18"><strong>Invoices:</strong> Create and send invoices to your customers and get paid easily, also receive invoices from other customers and pay them.</p>
                                  </li>
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="fs-18"><strong>Estimates:</strong> Negotiate prices with vendors when you receive an estimate before getting billed, interact eficiently.</p>
                                  </li>
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="fs-18"><strong>Purchase Order:</strong> Receive purchase orders to your vendors and negotiate a suitable price before getting billing them.</p>
                                  </li>
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="fs-18"><strong>Sales Receipt:</strong> See all your customized receipts from your customers after a severy transaction.</p>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="row gy-5 gy-lg-0 flex-row-reverse align-items-center justify-content-lg-between">
                      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="50">
                          <div class="nk-feature-overview-img mb-5 mb-lg-0">
                              <img src="/images/thumb/payments.png" alt="cover-base" class="base">
                          </div>
                      </div>
                      <div class="col-lg-6 col-xl-5">
                          <div class="nk-section-head pb-0">
                              <h2 class="nk-section-title mb-0">Instant Payment</span>
                              </h2>
                              <ul class="nk-list-dot d-flex flex-column gap-3 gap-lg-4 pt-4">
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="lead">With the integration of trusted gateway such as Stripe and Paystack, you can get your payment made instantly and securely once your customers make payments.</p>
                                  </li>
                                  <li class="nk-list-dot-item ps-3 p-0">
                                      <p class="lead">We're also implementing a blockchain payment method so you can start receiving your payments using your secured crypto wallet.</p>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section> */}
  {/* Story-Line Section End */}
  {/* Features Section Start  */}
  <section className="nk-section nk-section-features py-7 py-lg-120 pt-lg-7 ">
    <div className="container">
      <div className="row gy-5">
        <div className="col-md-6" data-aos="fade-up" data-aos-delay={0}>
          <div className="card text-center">
            <div className="card-body p-lg-7">
              <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-4">
                <em className="icon ni ni-trend-up" />
              </div>
              <div className="mb-4">
                <h4 className="mb-2 text-capitalize">Custom analytics</h4>
                <p className="fs-16 text-gray-1200">
                  Get a complete sales dashboard in the cloud. See activity,
                  revenue and social metrics all in one.
                </p>
              </div>
              <NavLink to="#" className="btn-link ">
                <span>Learn More</span>
                <em className="icon ni ni-arrow-right" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up" data-aos-delay={50}>
          <div className="card text-center">
            <div className="card-body p-lg-7">
              <div className="media media-lg media-middle media-circle text-bg-warning-soft mb-4">
                <em className="icon ni ni-users" />
              </div>
              <div className="mb-4">
                <h4 className="mb-2 text-capitalize">Team Management</h4>
                <p className="fs-16 text-gray-1200">
                  {" "}
                  Our calendar lets you know what is happening with customer and
                  projects so you{" "}
                </p>
              </div>
              <NavLink to="#" className="btn-link ">
                <span>Learn More</span>
                <em className="icon ni ni-arrow-right" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up" data-aos-delay={0}>
          <div className="card text-center">
            <div className="card-body p-lg-7">
              <div className="media media-lg media-middle media-circle text-bg-danger-soft mb-4">
                <em className="icon ni ni-edit-alt" />
              </div>
              <div className="mb-4">
                <h4 className="mb-2 text-capitalize">Build your website</h4>
                <p className="fs-16 text-gray-1200">
                  A tool that lets you build a dream website even if you know
                  nothing about web design.
                </p>
              </div>
              <NavLink to="#" className="btn-link ">
                <span>Learn More</span>
                <em className="icon ni ni-arrow-right" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up" data-aos-delay={50}>
          <div className="card text-center">
            <div className="card-body p-lg-7">
              <div className="media media-lg media-middle media-circle text-bg-success-soft mb-4">
                <em className="icon ni ni-emails" />
              </div>
              <div className="mb-4">
                <h4 className="mb-2 text-capitalize">
                  Measure your performance
                </h4>
                <p className="fs-16 text-gray-1200">
                  {" "}
                  Stay connected with your team and make quick decisions
                  wherever you are.{" "}
                </p>
              </div>
              <NavLink to="#" className="btn-link">
                <span>Learn More</span>
                <em className="icon ni ni-arrow-right" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Features Section End  */}
  {/* Values Section Start */}
  <section className="nk-section">
    <div className="nk-mask blur-1 left center" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle">other features</span>
            <h2 className="nk-section-title mb-0">
              Learn About Some Other Features
            </h2>
          </div>
        </div>
      </div>
      <div className="row gy-5">
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-4">
                <em className="icon ni ni-credit-card-fill" />
              </div>
              <h5>Accept Online Payments</h5>
              <p> Receive instant online invoice payments from anywhere.</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-info-soft mb-4">
                <em className="icon ni ni-clock-fill" />
              </div>
              <h5>Track Time &amp; Mileage</h5>
              <p>Turn hours worked into invoices and bills in just one click</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-warning-soft mb-4">
                <em className="icon ni ni-company-fill" />
              </div>
              <h5>Manage Multiple Businesses</h5>
              <p>
                {" "}
                Send bills from multiple businesses with their own branding
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-danger-soft mb-4">
                <em className="icon ni ni-view-grid-wd" />
              </div>
              <h5>All your finances in one place</h5>
              <p>
                {" "}
                Manage all your finances in one place and see your billed and
                paid invoices.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-4">
                <em className="icon ni ni-bell-fill" />
              </div>
              <h5>Inventory Management</h5>
              <p>
                {" "}
                Have a space fpr storing all your products and services with
                their prices to bill customers{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-success-soft mb-4">
                <em className="icon ni ni-puzzle-fill" />
              </div>
              <h5>Customize Bills</h5>
              <p>
                {" "}
                Customize your bills to match your business brand identity{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Values Section End*/}
  {/* Device Section Start */}
  {/* <section class="nk-section">
          <div class="nk-mask blur-1 right center"></div>
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-8 col-xxl-6">
                      <div class="nk-section-head text-center">
                          <span class="nk-section-subtitle">Available on every device</span>
                          <h2 class="nk-section-title mb-0">You can send invoice on any platform you're working with!</h2>
                      </div>
                  </div>
              </div>
              <div class="nk-responsive-img text-center" data-aos="fade-up" data-aos-delay="50">
                  <img src="/images/thumb/o.png" alt="" class="w-100">
              </div>
          </div>
      </section> */}
  {/* Device Section End */}
  {/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-0 align-items-center overflow-hidden">
          <div className="col-lg-7 order-lg-0 order-2">
            <div className="nk-block-head-content  mb-0">
              <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                Start billing your customers and boost your business finances
              </h2>
              <ul className="nk-btn-group flex-wrap ps-1">
                <li>
                  <a
                    href="https://app.billery.io/auth/register"
                    className="btn btn-white"
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 order-lg-0 order-1">
            <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
              <img
                data-aos="fade-left"
                data-aos-delay={200}
                src="/images/thumb/c.png"
                alt="tracking-thumb"
                className="me-n1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default Features