import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../component/css/Navbar.css';

function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false); // Track scroll state

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const hideNavbar = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true); // Set scrolled to true when past 50px
      } else {
        setScrolled(false); // Set scrolled to false when at the top
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`nk-header ${scrolled ? 'scrolled' : ''}`}>
        <div className="nk-header-main nk-navbar-main has-separate">
          <div className="container">
            <div className="nk-header-wrap">
              <div className="nk-header-logo">
                <NavLink to="/" className="logo-link" onClick={hideNavbar}>
                  <div className="logo-wrap">
                    <img
                      className="logo-img"
                      src="../images/billery-logo.svg"
                      alt="brand-logo"
                    />
                  </div>
                </NavLink>
              </div>
              <nav className="nk-header-menu nk-navbar">
                <div>
                  <ul className="nk-nav">
                    <li className="nk-nav-item">
                      <NavLink to="/" className="nk-nav-link">
                        <span className="nk-nav-text">Home</span>
                      </NavLink>
                    </li>
                    <li className="nk-nav-item">
                      <NavLink to="/about" className="nk-nav-link">
                        <span className="nk-nav-text">About</span>
                      </NavLink>
                    </li>
                    <li className="nk-nav-item">
                      <NavLink to="/contact" className="nk-nav-link">
                        <span className="nk-nav-text">Contact</span>
                      </NavLink>
                    </li>
                  </ul>
                  <div className="nk-navbar-btn d-lg-none">
                    <ul className="nk-btn-group sm justify-content-center">
                      <li className="nk-nav-item">
                        <NavLink to="https://app.billery.io" className="nk-nav-link">
                          <span className="nk-nav-text">Login</span>
                        </NavLink>
                      </li>
                      <li className="w-100">
                        <NavLink to="https://app.billery.io/auth/register" className="btn btn-primary w-100">
                          <span>Sign Up</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className={`overlay ${isVisible ? 'active' : ''}`} onClick={hideNavbar}></div>
              <div className={`myNavbar ${isVisible ? 'slide-in' : 'slide-out'}`}>
                <div className="innerNavbar">
                  <div className="inner-one">
                    <ul className='first'>
                      <li className='responsive m-3'>
                        <NavLink to={'/'} onClick={hideNavbar}>
                          <span className='navbar-content'>Home</span>
                        </NavLink>
                      </li>
                      <li className='responsive m-3'>
                        <NavLink to={'/about'} onClick={hideNavbar}>
                          <span className='navbar-content'>About</span>
                        </NavLink>
                      </li>
                      <li className='responsive m-3'>
                        <NavLink to={'/contact'} onClick={hideNavbar}>
                          <span className='navbar-content'>Contact</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="second-one">
                    <ul className='second'>
                      <li onClick={hideNavbar}>
                        <NavLink to="https://app.billery.io">
                          <span className="navtext nk-nav-link">Login</span>
                        </NavLink>
                      </li>
                      <li onClick={hideNavbar}>
                        <NavLink to="https://app.billery.io/auth/register">
                          <span className='signUp btn btn-primary w-100'>Sign Up</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="nk-header-action">
                <ul className="nk-btn-group sm justify-content-center align-items-center">
                  <li className="d-none d-md-block">
                    <NavLink to="https://app.billery.io" className="nk-nav-link">
                      <span className="nk-nav-text">Login</span>
                    </NavLink>
                  </li>
                  <li className="d-none d-md-block">
                    <NavLink to="https://app.billery.io/auth/register" className="btn btn-primary w-100">
                      <span>Sign Up</span>
                    </NavLink>
                  </li>
                  <li className="nk-navbar-toggle">
                    <button
                      className="btn btn-outline-dark navbar-toggle rounded-1 p-2 h-100"
                      onClick={toggleVisibility}
                    >
                      <em className="icon ni ni-menu" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
