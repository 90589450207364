import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const allowedEmails = ['info@billery.io', 'lilstex4good@gmail.com']; // List of allowed emails

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the entered email is in the list of allowed emails
    if (!allowedEmails.includes(email.toLowerCase())) {
      showErrorToast("Email not authorized.");
      return;
    }

    try {
      const response = await axios.post('https://billery-dev-0dd7e2a8d738.herokuapp.com/admin/login', {
        email: email,
        password: password,
      });

      // Handle successful login
      if (response.status === 200) {
        showSuccessToast("Login successful!");
        // Redirect the user here after login
      }
    } catch (error) {
      // Handle error
      if (error.response && error.response.data) {
        showErrorToast(error.response.data.message || "Login failed. Please try again.");
      } else {
        showErrorToast("An error occurred. Please try again.");
      }
    }

    // Clear form after submitting
    setEmail("");
    setPassword("");
  };

  return (
    <>
      <main className="nk-pages">
        <div className="nk-split-page flex-column flex-xl-row">
          <div className="nk-split-col nk-auth-col">
            <div
              className="nk-form-card card rounded-3 card-gutter-md nk-auth-form-card mx-md-9 mx-xl-auto"
              data-aos="fade-up"
            >
              <div className="card-body p-5">
                <div className="nk-form-card-head text-center pb-5">
                  <div className="form-logo mb-3"></div>
                  <h3 className="title mb-2">Login to blog</h3>
                  <p className="text">
                    New admin?{" "}
                    <NavLink to="/admin-login" className="btn-link text-primary">
                      Request Login
                    </NavLink>
                    .
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">E-mail</label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Password</label>
                        <div className="form-control-wrap">
                        <button
                          type="button"
                          className="form-control-icon end password-toggle"
                          title="Toggle show/hide password"
                          onClick={togglePasswordVisibility}
                          style={{ background: 'none', border: 'none', padding: 0 }} // Inline style to remove padding and background
                        >
                          {showPassword ? (
                            <em className="fas fa-eye text-primary" style={{ fontSize: '16px', color: '#000' }} /> // Adjust icon size and color
                          ) : (
                            <em className="fas fa-eye-slash text-primary" style={{ fontSize: '16px', color: '#000' }} />
                          )}
                        </button>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex flex-wrap align-items-center justify-content-between text-center">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="rememberMe"
                          />
                          <label className="form-check-label" htmlFor="rememberMe">
                            {" "}
                            Remember Me{" "}
                          </label>
                        </div>
                        <NavLink
                          to="/forgot-password"
                          className="d-inline-block fs-16"
                        >
                          Forgot Password?
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb-2">
                        <button className="btn btn-block btn-primary" type="submit">
                          Login
                        </button>
                      </div>
                      <div className="form-group">
                        <NavLink to="/admin-login">
                          <button className="btn btn-block" type="button">
                            Go home
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Toast Container to render toast messages */}
        <ToastContainer />
      </main>
    </>
  );
}

export default Login;
