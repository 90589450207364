import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'

function Page404() {
  return (
    <>
    <Header />
        <main className="nk-pages">
  <section className="nk-error-section nk-sectin-space-lg pt-120 pt-xl-180  overflow-hidden">
    <div className="nk-mask blur-1 left top" />
    <div className="nk-mask blur-1 right bottom" />
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-xl-7">
          <div className="nk-block-head">
            <div className="nk-section-head">
              <div className="error-number mb-5">404</div>
              <h2 className="nk-block-title m-0 mb-2 mb-md-4">
                Oops, we're sorry to interupt you :)
              </h2>
              <p className="nk-block-text lead ">
                We are currently making some changes to help you bill better,
                please check back later!
              </p>
              <ul className="nk-btn-group pt-4 justify-content-center pt-3 pt-md-5 pb-2">
                <li>
                  <NavLink to="/" className="btn btn-primary">
                    {" "}
                    Go Back Home{" "}
                  </NavLink>
                </li>
              </ul>
              <hr className="mt-md-6" />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-gs">
        <div className="col-md-12">
          <div className="card bg-primary-100 border-0 h-100 rounded-2">
            <div className="card-body d-flex flex-column">
              <div className="media media-lg media-middle media-circle text-bg-primary mb-3 mb-md-5">
                <em className="icon ni ni-help-fill" />
              </div>
              <h4 className="card-title mb-2 mb-md-1">Read the FAQs</h4>
              <p className="fs-16 mb-0">
                See some frequently asked questions and get to learn more about
                what we do..
              </p>
              <div className=" pt-3 pt-md-5 mt-auto">
                <NavLink to="help-center" className="btn-link text-primary">
                  <span>Read FAQS</span>
                  <em className="icon ni ni-arrow-right" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<Footer />

    </>
  )
}

export default Page404