import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'

function Privacy() {
  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Terms Section Start */}
  <section className="nk-section pt-120 pt-lg-180">
    <div className="nk-mask blur-1 left top" />
    <div className="nk-mask blur-1 right bottom" />
    <div className="container">
      <div className="nk-block-head md">
        <div className="nk-section-head">
          <nav>
            <ol className="breadcrumb mb-3 mb-md-4">
              <li className="breadcrumb-item">
                <a href="help-center.html">Support</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </li>
            </ol>
          </nav>
          <h2>Privacy Policy</h2>
        </div>
        <hr className="mt-5 mb-0" />
      </div>
      <div className="row g-gs pt-3 ">
        <div className="col-lg-12">
          <div className="nk-entry-wrap pt-3 ps-lg-5">
            <div className="nk-entry">
              <div className="pb-5">
                <h5>Information We Collect</h5>
                <p className="fs-16">
                  <strong>Personal Information:</strong> When you use the
                  System, we may collect personal information such as your name,
                  email address, contact information, and other relevant details
                  necessary for the proper functioning of the System.
                </p>
                <p className="fs-16">
                  <strong>Usage Information:</strong> We may collect information
                  about your use of the System, including log files, device
                  information, and usage patterns. This information is collected
                  to improve the performance and functionality of the System.
                </p>
              </div>
              <div className="pb-5">
                <h5>How We Use Your Information</h5>
                <p className="fs-16">
                  <strong>To Provide Services: </strong>We use your personal
                  information to provide you with access to and use of the
                  System, including processing invoices and managing your
                  account.
                </p>
                <p className="fs-16">
                  <strong>To Improve the System: </strong>We may use your
                  information to analyze usage patterns and preferences,
                  allowing us to enhance the System's features and
                  functionality.{" "}
                </p>
                <p className="fs-16">
                  <strong>To Communicate:</strong>We may use your contact
                  information to send you important updates, notifications, and
                  marketing communications related to the System. You can
                  opt-out of marketing communications at any time.{" "}
                </p>
              </div>
              <div className="pb-5">
                <h5>How We Share Your Information</h5>
                <p className="fs-16">
                  <strong>Service Providers:</strong> We may share your
                  information with third-party service providers who assist us
                  in operating the System or conducting our business.
                </p>
                <p className="fs-16">
                  <strong>Legal Requirements:</strong> We may disclose your
                  information if required by law or in response to a valid legal
                  request.{" "}
                </p>
                <p className="fs-16">
                  <strong>Business Transfers:</strong> If we are involved in a
                  merger, acquisition, or sale of all or a portion of our
                  assets, your information may be transferred as part of that
                  transaction.{" "}
                </p>
              </div>
              <div className="pb-5">
                <h5>Data Security</h5>
                <p className="fs-16">
                  {" "}
                  We take reasonable measures to protect your personal
                  information from unauthorized access, disclosure, or
                  alteration. However, no data transmission over the internet or
                  storage system is entirely secure, and we cannot guarantee the
                  absolute security of your data.
                </p>
              </div>
              <div className="pb-5">
                <h5>Your Choices</h5>
                <p className="fs-16">
                  You can access and update your personal information by logging
                  into your account. You can also request the deletion of your
                  account and data by contacting us.
                </p>
              </div>
              <div className="pb-5">
                <h5> Children's Privacy</h5>
                <p className="fs-16">
                  {" "}
                  The System is not intended for use by individuals under the
                  age of 18. We do not knowingly collect personal information
                  from children under 18. If you believe that we have
                  unintentionally collected information from a child, please
                  contact us, and we will take appropriate steps to delete the
                  information.
                </p>
              </div>
              <div className="pb-5">
                <h5>Changes to this Privacy Policy</h5>
                <p className="fs-16">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. The updated Privacy Policy will be posted
                  on our website, and the "Last Updated" date will be revised
                  accordingly.
                </p>
              </div>
              <div className="pb-5">
                <h5>Contact Information</h5>
                <p className="fs-16">
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or the data we collect, please contact us
                  at support@myinvoicer.app.
                </p>
                <p className="fs-16">
                  By using the MyInvoicer, you acknowledge that you have read,
                  understood, and agree to this Privacy Policy.
                </p>
              </div>
            </div>
            {/* .nk-entry */}
          </div>
          {/* .nk-entry-wrap */}
        </div>
      </div>
    </div>
  </section>
  {/* Terms Section End */}
  &lt;{/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-0 align-items-center overflow-hidden">
          <div className="col-lg-7 order-lg-0 order-2">
            <div className="nk-block-head-content  mb-0">
              <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                Start billing your customers and boost your business finances
              </h2>
              <ul className="nk-btn-group flex-wrap ps-1">
                <li>
                  <a
                    href="https://app.billery.io/auth/register"
                    className="btn btn-white"
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 order-lg-0 order-1">
            <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
              <img
                data-aos="fade-left"
                data-aos-delay={200}
                src="/images/thumb/c.png"
                alt="tracking-thumb"
                className="me-n1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default Privacy