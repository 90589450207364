import React from 'react'
import { useEffect } from 'react';

function Preloader() {
  const load = function (selector) {

    let preloaderElement = document.querySelector(selector);

    if (preloaderElement) {
      setTimeout(() => {
        preloaderElement.classList.add('hide');
      }, 700);
    }
  };

  useEffect(() => {
    load('.preloader');
  }, [])
  return (
    <>
        <div className="preloader">
          <div className="loader" />
        </div>
    </>
  )
}

export default Preloader