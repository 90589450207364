import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'

function Billing() {
  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Header Section End */}
  <section className="nk-section pt-120 pt-lg-180">
    <div className="nk-mask blur-1 left top" />
    <div className="nk-mask blur-1 right bottom" />
    <div className="container">
      <div className="nk-section-head">
        <nav>
          <ol className="breadcrumb mb-3 mb-md-4">
            <li className="breadcrumb-item">
              <a href="help-center">Support</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Billing &amp; Payments
            </li>
          </ol>
        </nav>
        <h2 className="nk-section-title">Billing &amp; Payments</h2>
        <hr className="mt-5 mb-0" />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="nk-entry-wrap pt-3 pslg-5">
            <div className="nk-entry">
              <h5>Is there a free trial version available?</h5>
              <p className="fs-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                ultricies ullamcorper orci vulputate mauris in nibh nullam
                elementum. Nibh purus, pretium sed pellentesque amet. Eget
                gravida ut vel amet ut id nunc, neque. Viverra purus pretium
                elementum tincidunt mollis.
              </p>
              <p className="fs-16">
                Ipsum, imperdiet nibh venenatis dictumst lectus neque, ante sit.
                Volutpat gravida commodo augue augue habitasse. Imperdiet
                ullamcorper bibendum ut sit lorem sit. Quam enim eu, velit
                ultricies nisl ultricies cras.
              </p>
              <img src="/images/help-center/a.jpg" alt="" />
              <h5>Offer your clients a self-service help center</h5>
              <p className="fs-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                ultricies ullamcorper orci vulputate mauris in nibh nullam
                elementum. Nibh purus, pretium sed pellentesque amet. Eget
                gravida ut vel amet ut id nunc, neque. Viverra purus pretium
                elementum tincidunt mollis.
              </p>
              <h5>Ease your Support team with Docs Help Center</h5>
              <p className="fs-16">
                At risus viverra adipiscing at in tellus integer feugiat nisl
                pretium fusce id velit ut tortor sagittis orci a scelerisque
                purus semper eget at lectus urna duis convallis. porta nibh
                venenatis cras sed felis eget neque laoreet suspendisse interdum
                consectetur libero nisl donec pretium vulputate sapien nec
                sagittis aliquam nunc lobortis mattis aliquam faucibus purus in.
              </p>
              <ul className="ms-2">
                <li className="fs-16">
                  Neque sodales ut etiam sit amet nisl purus non tellus orci ac
                  auctor
                </li>
                <li className="fs-16">
                  Adipiscing elit ut aliquam purus sit amet viverra suspendisse
                  potenti
                </li>
                <li className="fs-16">
                  Mauris commodo quis imperdiet massa tincidunt nunc pulvinar
                </li>
                <li className="fs-16">
                  Adipiscing elit ut aliquam purus sit amet viverra suspendisse
                  potenti
                </li>
              </ul>
              <img src="/images/help-center/b.jpg" alt="" />
              <h5>Docs Changelog helps you announce new features</h5>
              <p className="fs-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                ultricies ullamcorper orci vulputate mauris in nibh nullam
                elementum. Nibh purus, pretium sed pellentesque amet. Eget
                gravida ut vel amet ut id nunc, neque. Viverra purus pretium
                elementum tincidunt mollis.
              </p>
              <p className="fs-16">
                Ipsum, imperdiet nibh venenatis dictumst lectus neque, ante sit.
                Volutpat gravida commodo augue augue habitasse. Imperdiet
                ullamcorper bibendum ut sit lorem sit. Quam enim eu, velit
                ultricies nisl ultricies cras.
              </p>
              <h5>Stay tuned to our ongoing updates</h5>
              <p className="fs-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                ultricies ullamcorper orci vulputate mauris in nibh nullam
                elementum. Nibh purus, pretium sed pellentesque amet. Eget
                gravida ut vel amet ut id nunc, neque. Viverra purus pretium
                elementum tincidunt mollis.
              </p>
              <p className="fs-16">
                Ipsum, imperdiet nibh venenatis dictumst lectus neque, ante sit.
                Volutpat gravida commodo augue augue habitasse. Imperdiet
                ullamcorper bibendum ut sit lorem sit. Quam enim eu, velit
                ultricies nisl ultricies cras. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Elit ultricies ullamcorper orci
                vulputate mauris in nibh nullam elementum. Nibh purus, pretium
                sed pellentesque amet. Eget gravida ut vel amet ut id nunc,
                neque. Viverra purus pretium elementum tincidunt mollis.
              </p>
            </div>
            {/* .nk-entry */}
          </div>
          {/* .nk-entry-wrap */}
        </div>
      </div>
    </div>
  </section>
  <section className="nk-section nk-cta-section py-5 py-lg-7">
    <div className="container">
      <div
        className="nk-cta-wrap bg-primary-gradient rounded-3 is-theme p-5 p-lg-7"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-gs align-items-center">
          <div className="col-lg-8">
            <div className="media-group flex-column flex-lg-row align-items-center">
              <div className="media media-lg media-circle media-middle text-bg-white text-primary mb-2 mb-lg-0 me-lg-2">
                <em className="icon ni ni-chat-fill" />
              </div>
              <div className="text-center text-lg-start ms-lg-2">
                <h3 className="text-capitalize">Chat with our support team!</h3>
                <p className="fs-16">
                  Get in touch with our support team if you still can’t find
                  your answer.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <a href="contact-us.html" className="btn btn-white">
              Contact support
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-0 align-items-center overflow-hidden">
          <div className="col-lg-7 order-lg-0 order-2">
            <div className="nk-block-head-content  mb-0">
              <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                Start billing your customers and boost your business finances
              </h2>
              <ul className="nk-btn-group flex-wrap ps-1">
                <li>
                  <a
                    href="https://app.billery.io/auth/register"
                    className="btn btn-white"
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 order-lg-0 order-1">
            <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
              <img
                data-aos="fade-left"
                data-aos-delay={200}
                src="/images/thumb/c.png"
                alt="tracking-thumb"
                className="me-n1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default Billing