import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogCards = ({ imageUrl, badgeText, cardTitle, linkTo, onDelete, onEdit }) => {
  return (
    <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
      <div className="card">
        <div className="card-body">
          <div className="card-image">
            <img src={imageUrl} alt="content" className="card-img" />
          </div>
          <div className="card-content pt-4">
            <span className="badge rounded-pill text-bg-primary-soft mb-2 mb-md-3">{badgeText}</span>
            <h5 className="card-title text-capitalize m-0">
              <NavLink to={linkTo}>{cardTitle}</NavLink>
            </h5>
            <div className="buttons pt-3">
              <button className="btn bg-primary text-white me-2" onClick={onEdit}>Edit</button>
              <button className="btn btn-danger" onClick={onDelete}>Delete</button>
            </div>
            <div className="media-group pt-4 align-items-center">
              <div className="media-text">
                <ul className="nk-list-meta smaller">
                  <li>2024</li>
                  <li>8 - 10 mins</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
