import React from 'react';
import { useParams } from 'react-router-dom';
import '../component/css/Blogs-single.css';
import Page404 from './Page404';

function BlogSingle({ blogs }) {
  const { id } = useParams(); 
  const blog = blogs.find(blog => blog.id.toString() === id);

  if (!blog) {
    return <Page404 />;
  }

  return (
    <>
      <main className="nk-pages">
        {/* Tech Section Start */}
        <section className="nk-section pt-120 pt-lg-160">
          <div className="nk-mask blur-1 left top" />
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                <div className="nk-section-head">
                  <span className="nk-section-subtitle" data-aos="fade-up">
                    tech
                  </span>
                  <h2
                    className="nk-section-title"
                    data-aos="fade-up"
                    data-aos-delay={50}
                  >
                    {blog.cardTitle}
                  </h2>
                  <ul
                    className="nk-list-meta justify-content-center pt-1"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <li>{blog.date || 'Date not available'}</li>
                    <li>{blog.readTime || 'Read time not available'}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
                <div className="nk-head-img pb-5 pb-md-7">
                  <img
                    src={blog.imageUrl}
                    alt="blog"
                    className="overflow-hidden rounded-3 img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="nk-section-blog-content">
              <div className="row gap g-5">
                <div className="col-lg-12">
                  <div className="nk-entry">
                    <div className="mb-3 mb-md-5">
                      <p className="fs-16 text-wrap">
                        {blog.blogText}
                      </p>
                    </div>
                  </div>
                  {/* .nk-entry */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Tech Section End */}
        {/* Other Sections */}
        {/* ... */}
      </main>
    </>
  );
}

export default BlogSingle;
