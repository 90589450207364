import React from 'react'
import { useEffect, useState } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../component/css/TopButton.css'


function TopButton() {
    const [topButton, setTopButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setTopButton(true);
            } else {
                setTopButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
  return (
    <>
 {topButton && (
    <button
        onClick={scrollUp}
        className={`scroll-to-top-btn shadow animate animate-infinite animate-pulse animate-duration-2 ${topButton ? 'show' : 'hide'}`}
    >
        <div
            style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <em className="icon ni ni-chevrons-up"></em>
        </div>
    </button>
)}

    </>

  )
}

export default TopButton